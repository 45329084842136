// Modules
import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Music from './pages/Music';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';

// Styling
//import { colors } from './constants/theme';
import Toast from './baseComponents/Toast';
import './App.css';
import Loading from './baseComponents/Loading';

function App() {
  const [loading, setLoading] = useState(true);

  /*
  componentDidMount() {
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));
  };

  callBackendAPI = async () => {
    const res = await fetch('/');
    const body = await res.json();

    if (res.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };
  */
  
  useEffect(() => {
    document.fonts.ready.then(() => { setLoading(false) });
    /*window.addEventListener('document.fonts.onloadingDone', setLoading.bind(this, false));
      return () => {
        window.removeEventListener('document.fonts.onloadingDone', setLoading);
      };*/
  }, []);

  return (
    <Router>
      <div className="App">
        <Toast content="Welcome!" form="stress" />
        <Toast content="The site is currently under construction, but some external links to my projects will be available soon!" />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/games" component={() => <Projects screen="games" />} />
          <Route path="/projects/other" component={() => <Projects screen="other" />} />
          <Route path="/music" exact component={Music} />
          <Route path="/contact" exact component={Contact} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
      {loading === true ? <Loading active="true"/> : <Loading active="false"/>}
    </Router>
  )
}

export default App;
