import React, { Component } from 'react';
import MiniMessage from '../baseComponents/MiniMessage';

export default class About extends Component{
  constructor(props) {
    super(props);
    this.state = { placeholder: 'about' };
  };

  render() {
    return (
      <MiniMessage content={`This is the ${this.state.placeholder} page.`}/>
    );
  }
}