import React, { Component } from 'react';
import MiniMessage from '../baseComponents/MiniMessage';

export default class Projects extends Component{
  constructor(props) {
    super(props);
    this.state = {
      placeholder: 'projects',
      screen: this.props.screen === undefined ? null : this.props.screen
    };
  };

  render() {
    return (
      <MiniMessage content={`This is the ${this.props.screen} section under the ${this.state.placeholder} page.`}/>
    );
  }
}